<template>
    <div class="letter"
         :class="isHovering(hoverLetter)"
         :style="computedStyle(settings.letter.style)"> <!---->
        <div class="letter-header"
             :class="isHovering(hoverHeader)"
             :style="computedStyle(settings.header.style)">

            <img :src="settings.logo.src" alt="Logo"
                 class="letter-logo"
                 :class="isHovering(hoverLogo)"
                 :style="computedStyle(settings.logo.style)">

            <div class="return-address"
                 :class="isHovering(hoverReturnAddress)"
                 :style="computedStyle(settings.returnAddress.style)">
                <div v-if="settings.returnAddress.text.line1" :contenteditable="editing" class="font-weight-bold">{{ settings.returnAddress.text.line1 }}</div>
                <div v-if="settings.returnAddress.text.line2" :contenteditable="editing">{{ settings.returnAddress.text.line2 }}</div>
                <div v-if="settings.returnAddress.text.line3" :contenteditable="editing">{{ settings.returnAddress.text.line3 }}</div>
                <div v-if="settings.returnAddress.text.line4" :contenteditable="editing">{{ settings.returnAddress.text.line4 }}</div>
                <div v-if="settings.returnAddress.text.line5" :contenteditable="editing">{{ settings.returnAddress.text.line5 }}</div>
            </div>
        </div>
        <div class="recipient-address"
             :class="isHovering(hoverRecipientAddress)"
             :style="computedStyle(settings.recipientAddress.style)">

          <!-- Teacher Name -->
          <template>
            <div v-if="hasValue(student, 'name')">{{ student.name.first }} {{ student.name.last }}</div>
            <div v-else class="text-danger">No Student</div>
          </template>

          <!-- Student School -->
<!--          <template>
            <div v-if="hasValue(student, 'school.name')">{{ student.school.name.legal }}</div>
            <div v-else class="text-danger">No School</div>
          </template>-->

          <!-- Student Address -->
          <template>
            <template v-if="hasValue(student, 'address')">
              <div>
                <span>{{ student.address.line1 }}</span>
                <span v-if="student.address.line2">, {{ student.address.line2 }}</span>
              </div>
              <div>{{ student.address.city }}, {{ student.address.state }} {{ student.address.zip }}</div>
            </template>
            <template v-else>
              <div class="text-danger">No Address</div>
            </template>
          </template>

        </div>
        <div class="letter-body"
             :class="isHovering(hoverBody)"
             :style="computedStyle(settings.body.style)">
            <template v-if="editing">
                <tip-tap v-model="settings.body.text" :show-toolbar="true"
                         :toolbar-options="{
                                headings: false, fonts: true, alignment: false, list: true, code: false, link: false,
                                image: true, table: false, codeBlock: false, clear: true, more: false,
                                schools: false, icons: false, options: true
                         }"
                />
            </template>
            <template v-else>
                <div v-html="renderedText"/>
            </template>
        </div>
        <div class="letter-footer" :class="isHovering(hoverFooter)" :style="computedStyle(settings.footer.style)">
          <hr class="letter-footer-hr w-100" />
          <div class="letter-footer-text" :style="computedStyle(settings.footer.line1.style)">{{ settings.footer.line1.text }}</div>
          <div class="letter-footer-text" :style="computedStyle(settings.footer.line2.style)">{{ settings.footer.line2.text }}</div>
        </div>


        <div v-if="showPageMarks || showFoldMarks" class="fold-guide">
          <div v-if="showPageMarks" class="fold-mark page-start"></div>
          <div v-if="showFoldMarks" class="fold-mark fold-mark-1"></div>
          <div v-if="showFoldMarks" class="fold-mark fold-mark-2"></div>
          <div v-if="showPageMarks" class="fold-mark page-end"></div>
        </div>
    </div>
</template>

<script>
import styleMixin from '@/mixins/style.mixin';
import TipTap from '@/components/TipTap.vue';
import lodashMixin from '@/mixins/lodash.mixin';

export default {
    name: 'StudentLetter',
    components: {TipTap},
    mixins: [ styleMixin, lodashMixin ],
    props: {
      editing: {
          type: Boolean,
          default: false
      },
      settings: {
          type: Object,
          required: true
      },
      showFoldMarks: {
        type: Boolean,
        default: true
      },
      showPageMarks: {
        type: Boolean,
        default: true
      },
      student: {
        type: Object,
        required: true
      },
      chairs: {
        type: Array,
        required: true
      },
      hoverLetter: {
        type: Boolean,
        default: false,
      },
      hoverHeader: {
        type: Boolean,
        default: false,
      },
      hoverLogo: {
        type: Boolean,
        default: false,
      },
      hoverReturnAddress: {
        type: Boolean,
        default: false,
      },
      hoverRecipientAddress: {
        type: Boolean,
        default: false,
      },
      hoverBody: {
        type: Boolean,
        default: false,
      },
      hoverFooter: {
        type: Boolean,
        default: false,
      },
      hoverFooterLeft: {
        type: Boolean,
        default: false,
      },
      hoverFooterRight: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      chair() {
        return this.chairs.find(chair => chair.id === this.student?.selection?.ensemble?.chairId);
      },
      renderedText() {
        let {text} = this.settings.body;
        const { student } = this;

        /** Special Blocks **/
        text = text.replace(/{{\s*year\s*}}/g, new Date().getFullYear());
        text = text.replace(/{{\s*signature\s*}}/g, this.renderSignature());

        text = text.replace(/{{\s*chairs\.\*\s*}}/g, this.renderChairsAll());
        text = text.replace(/{{\s*chairs\.\[.*?\]\s*}}/g, this.renderChairsUserDefined());
        text = text.replace(/{{\s*chair\.\*\s*}}/g, this.renderChairAll());

        // Student Name
        text = text.replace(/{{\s*student\.name\.first\s*}}/g, student?.name?.first);
        text = text.replace(/{{\s*student\.name\.last\s*}}/g, student?.name?.last);
        text = text.replace(/{{\s*student\.name\.\*\s*}}/g, `${student?.name?.first} ${student?.name?.last}`);

        // Student Selection
        text = text.replace(/{{\s*student\.selection\.ensemble\.name\s*}}/g, student?.selection?.ensemble?.name ?? '')
        text = text.replace(/{{\s*student\.selection\.instrument\.name\s*}}/g, student?.selection?.instrument?.name ?? '');
        text = text.replace(/{{\s*student\.selection\.part\s*}}/g, student?.selection?.part ?? '');

        // Student Address
        text = text.replace(/{{\s*student\.address\.line1\s*}}/g, student?.address?.line1);
        text = text.replace(/{{\s*student\.address\.line2\s*}}/g, student?.address?.line2);
        text = text.replace(/{{\s*student\.address\.city\s*}}/g, student?.address?.city);
        text = text.replace(/{{\s*student\.address\.state\s*}}/g, student?.address?.state);
        text = text.replace(/{{\s*student\.address\.zip\s*}}/g, student?.address?.zip);
        text = text.replace(/{{\s*student\.address\.\*\s*}}/g, `
            <div class="recipient-address">
              <div>${student?.address?.line1 ?? ''}</div>
              <div>${student?.address?.line2 ?? ''}</div>
              <div>${student?.address?.city ?? ''}, ${student?.address?.state ?? ''} ${student?.address?.zip ?? ''}</div>
            </div>
          `);

        // Chair
        text = text.replace(/{{\s*chair\.name\.first\s*}}/g, `<span>${this.chair?.name?.first}</span>`);
        text = text.replace(/{{\s*chair\.name\.last\s*}}/g, `<span>${this.chair?.name?.last}</span>`);
        text = text.replace(/{{\s*chair\.name\.\*\s*}}/g, `<span>${this.chair?.name?.first} ${this.chair?.name?.last}</span>`);
        text = text.replace(/{{\s*chair\.email\s*}}/g, `<span>${this.chair?.email ?? ''}</span>`);
        text = text.replace(/{{\s*chair\.phone\s*}}/g, `<span>${this.formatPhoneNumber(this.chair?.phone ?? '')}</span>`);
        text = text.replace(/{{\s*chair\.address\.\*\s*}}/g, `
          <div class="recipient-address">
            <div>${this.chair?.address?.line1 ?? ''}</div>
            <div>${this.chair?.address?.line2 ?? ''}</div>
            <div>${this.chair?.address?.city ?? ''}, ${this.chair?.address?.state ?? ''} ${this.chair?.address?.zip ?? ''}</div>
          </div>
        `);

        /** Return **/
        return text;
      },
    },
    methods: {
      renderChairsUserDefined() {
        const { ensemble } = this.student.selection;
        const sortedChairs = this.chairs
        .filter(chair => chair.ensembleIds?.includes(ensemble?.id)) // Ensure the chair is in the ensemble
        .sort((a, b) => {
          // Check if either a or b is the primary chairperson
          if (a.id === ensemble.chairId) return -1;
          if (b.id === ensemble.chairId) return 1;

          // Sort by first name, then last name
          const nameA = `${a.name.first} ${a.name.last}`.toLowerCase();
          const nameB = `${b.name.first} ${b.name.last}`.toLowerCase();
          return nameA.localeCompare(nameB)
        });


        const bodyText = this.settings.body.text.match(/{{\s*chairs\.\[.*?\]\s*}}/g);
        if(!bodyText || !bodyText?.length) return ''
        const chairsBlock = sortedChairs.filter(chair => chair.ensembleIds?.includes(ensemble?.id)).map(chair => {
          const regexInner = /{{\s*chairs\.\[\s*([\s\S]*?)\s*\]\s*}}/g;
          const matches = [...bodyText[0].matchAll(regexInner)];
          let text = ''
          matches.forEach(([_, extractedText]) => { text = extractedText });
          text = text.replace('<br>', '')
          text = text.replace(/{{\s*chair\.name\.first\s*}}/g, `<span>${chair?.name?.first}</span>`);
          text = text.replace(/{{\s*chair\.name\.last\s*}}/g, `<span>${chair?.name?.last}</span>`);
          text = text.replace(/{{\s*chair\.name\.\*\s*}}/g, `<span>${chair?.name?.first} ${chair?.name?.last}</span>`);
          text = text.replace(/{{\s*chair\.email\s*}}/g, `<span>${chair?.email ?? ''}</span>`);
          text = text.replace(/{{\s*chair\.phone\s*}}/g, `<span>${this.formatPhoneNumber(chair?.phone ?? '')}</span>`);
          text = text.replace(/{{\s*chair\.address\.\*\s*}}/g, `
            <div class="recipient-address">
              <div>${chair?.address?.line1 ?? ''}</div>
              <div>${chair?.address?.line2 ?? ''}</div>
              <div>${chair?.address?.city ?? ''}, ${chair?.address?.state ?? ''} ${chair?.address?.zip ?? ''}</div>
            </div>
          `);
          return `<div>${text}</div>`;
        }).join('');
        return `<div class="chair-grid" style="display: grid;grid-gap: 0.365in;grid-template-columns: repeat(auto-fill, 2in);">${chairsBlock}</div>`;
      },

      renderChairsAll() {
        const chairsBlock = this.chairs.filter(chair => chair.ensembleIds?.includes(this.student?.selection?.ensemble?.id)).map(chair => {
        let text = `
          <div>
            <div>{{ chair.name.first }} {{ chair.name.last }}</div>
            <div>{{ chair.email }}</div>
            <div>{{ chair.phone }}</div>
            <div>{{ chair.address.* }}</div>
          </div>
        `;

        text = text.replace(/{{\s*chair\.name\.first\s*}}/g, chair?.name?.first);
        text = text.replace(/{{\s*chair\.name\.last\s*}}/g, chair?.name?.last);
        text = text.replace(/{{\s*chair\.name\.\*\s*}}/g, `${chair?.name?.first} ${chair?.name?.last}`);
        text = text.replace(/{{\s*chair\.email\s*}}/g, chair?.email);
        text = text.replace(/{{\s*chair\.phone\s*}}/g, this.formatPhoneNumber(chair?.phone));
        text = text.replace(/{{\s*chair\.address\.\*\s*}}/g, `
          <div class="recipient-address">
            <div>${chair?.address?.line1}</div>
            <div>${chair?.address?.line2 ? `${chair?.address?.line2}` : ''}</div>
            <div>${chair?.address?.city}, ${chair?.address?.state} ${chair?.address?.zip}</div>
          </div>
        `);

        return text;
      }).join('');
      return `<div class="chair-grid" style="display: grid;grid-gap: 0.365in;grid-template-columns: repeat(auto-fill, 2in);">${chairsBlock}</div>`;
    },

      renderChairAll() {
        const chairsBlock = this.chairs.filter(chair => chair.id === this.student?.selection?.ensemble?.chairId).map(chair => {
          let text = `
          <div>
            <div>{{ chair.name.first }} {{ chair.name.last }}</div>
            <div>{{ chair.email }}</div>
            <div>{{ chair.phone }}</div>
            <div>{{ chair.address.* }}</div>
          </div>
        `;

          text = text.replace(/{{\s*chair\.name\.first\s*}}/g, chair?.name?.first);
          text = text.replace(/{{\s*chair\.name\.last\s*}}/g, chair?.name?.last);
          text = text.replace(/{{\s*chair\.name\.\*\s*}}/g, `${chair?.name?.first} ${chair?.name?.last}`);
          text = text.replace(/{{\s*chair\.email\s*}}/g, chair?.email);
          text = text.replace(/{{\s*chair\.phone\s*}}/g, this.formatPhoneNumber(chair?.phone));
          text = text.replace(/{{\s*chair\.address\.\*\s*}}/g, `
          <div class="recipient-address">
            <div>${chair?.address?.line1}</div>
            <div>${chair?.address?.line2 ? `${chair?.address?.line2}` : ''}</div>
            <div>${chair?.address?.city}, ${chair?.address?.state} ${chair?.address?.zip}</div>
          </div>
        `);

          return text;
        }).join('');
        return `<div class="chair-grid" style="display: grid;grid-gap: 0.365in;grid-template-columns: repeat(auto-fill, 2in);">${chairsBlock}</div>`;
      },

      renderSignature() {
        const { style, text } = this.settings?.body?.signature
        return `<p style="${this.computedStyle(style)}">
          ${text}
        </p>`
      },

      isHovering(prop) {
        return prop ? 'hover-border' : '';
      },

      formatPhoneNumber(phoneNumber) {
        if(!phoneNumber) return ''
        const cleaned = phoneNumber.replace(/^\+1|\D/g, '');

        if (cleaned.length !== 10) {
          return phoneNumber
        }
        return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }
    }
};
</script>

<style scoped>
  .hover-border {
    background: rgba(33, 41, 105, 0.12) !important
  }
  .letter {
      width: 8.5in;
      min-height: 11in;
      box-sizing: border-box;
      position: relative;
      font-family: 'Times New Roman', serif;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border: 1px solid rgba(34, 41, 47, 0.125);
      border-radius: 0.428rem;
      box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
      margin-bottom: 1rem;
  }

  @media print {
    .letter {
      border: unset;
      margin-bottom: unset;
      page-break-after: always; /* for older browsers */
      break-after: page; /* modern standard */
    }
  }


  .fold-guide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      /*height: 100%;*/
  }

  .letter-header {
      display: flex;
      justify-content: space-between;
      margin-top: 0.875in;
      margin-left: 0.875in;
      margin-right: 0.875in;
  }

  .letter-logo {
      width: 1.25in;
      height: 1.25in;
      border-radius: 50%;
      object-fit: cover;
      background: #fff;
  }

  .return-address {
      margin: auto 0 auto 0;
      text-align: right;
  }

  .recipient-address {
      position: absolute;
      top: 2.5in;
      left: 0.875in;
      width: 3.625in;
      height: 0.75in;
      line-height: 1.25;
      word-wrap: break-word;
  }

  /*.letter-body {
      position: absolute;
      top: 4in;
      !*margin-top: 2.25in;*!
      margin-left: 0.875in;
      margin-right: 0.875in;
      font-size: 12pt;
      line-height: 1.5;
  }*/

  .letter-body {
    margin: 2in 0.875in 0 0.875in;
    line-height: 1.5;
  }


  .letter-footer-hr {
    width: 100%;
    background: #6e6b7b;
    margin: 1.5rem 0;
  }

  .letter-footer {
    font-size: 1em;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: auto;
    bottom: 0;
    width: 100%;
    padding: 0.125in 0.875in 0.25in 0.875in;
  }



  .letter-footer-title {
    font-size: 1em;
    font-weight: bold;
  }
  .letter-footer-text {
    font-size: 1em;
    font-weight: normal;
  }

  div[contenteditable=true]:focus-visible {
      background: #f6f6f6;
      outline: none;
      border: 1px solid #ccc;
      border-radius: 0.357rem;
      padding: 0 4px 0 4px;
  }

  .fold-mark {
      position: absolute;
      width: 100%;
      border-top: 1px dashed #ccc;
  }

  .fold-mark-1 {
      top: 3.67in;
  }

  .fold-mark-2 {
      top: 7.33in;
  }

  .page-start{
    top: 0;
    border-top: 1px dashed red;
  }

  .page-end {
    top: 11in;
    border-top: 1px dashed red;
  }
</style>

<style scoped>
  /* Print-specific styles */
  @media print {
      .fold-mark {
          display: none;
      }
  }
</style>
