<template>
	<page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Printable - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Letters" active />
      <b-breadcrumb-item text="Student" active />
    </template>

		<template #actions="{ state }">
			<b-button v-if="state.editing" variant="outline-primary" size="sm" class="d-inline-flex align-items-center mr-50" @click="state.editing = false">
				<font-awesome-icon icon="fa-solid fa-pen-to-square" class="mr-50" /> Editing
			</b-button>
		</template>

		<template #dropdown-options="{ state }">
			<b-dropdown-item @click="state.editing = !state.editing">
				<font-awesome-icon icon="fa-solid fa-pen-to-square" />
				<span class="align-middle ml-50">Edit</span>
			</b-dropdown-item>
			<b-dropdown-item @click="refresh">
				<feather-icon icon="RotateCwIcon"/>
				<span class="align-middle ml-50">Refresh</span>
			</b-dropdown-item>
		</template>

    <template #loading="{ state }">
      <overlay-loading :items="[
          { state: ensembles.loading, desc: 'Loading Ensembles', loaded: ensembles.loaded},
          { state: chairs.loading, desc: 'Loading Chairs', loaded: chairs.loaded},
          { state: students.loading, desc: 'Loading Selections', loaded: students.loaded},
          { state: state.loading, desc: 'Rendering Template'},
          ]">
      </overlay-loading>
    </template>

		<template #content="{ state }">
			<b-row>
				<b-col :cols="20" class="letter-col-sidebar d-print-none">
					<!-- Settings -->
          <template v-if="state.editing">
            <!-- Settings -->
            <settings-container title="Letter Settings"
                                :settings="settings"
                                :settings-key="key"
                                :has-changed="hasChanges"
                                @revert="settings = JSON.parse(JSON.stringify(initialSettings))"
                                @updated="syncInitialSettings">
              <!-- Letter -->
              <b-card no-body class="mb-0">
                <options-header v-b-toggle.options-letter v-b-hover="hoverLetter" header-class="options-header" title="Letter"></options-header>
                <b-collapse id="options-letter" role="tabpanel" class="options-collapse">
                  <b-card-body body-bg-variant="light-primary">

                    <!-- Font Options -->
                    <options-header v-b-toggle.options-letter-font header-class="options-header" title="Font"></options-header>
                    <b-collapse id="options-letter-font" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-color v-model="settings.letter.style.color" label="Font Color" input-id="letter-color"/>
                        <option-value-unit v-model="settings.letter.style.fontSize" label="Font Size" input-id="letter-font-size" placeholder="1.5rem"/>
                        <option-select v-model="settings.letter.style.fontFamily" label="Font Family" input-id="letter-font-family" :options="options.fontFamily" placeholder="Times New Roman"/>
                        <option-select v-model="settings.letter.style.fontWeight" label="Font Weight" input-id="letter-font-weight" :options="options.fontWeight" placeholder="unset"/>
                      </b-card-body>
                    </b-collapse>

                    <!-- Margin Options -->
                    <options-header v-b-toggle.options-letter-margin header-class="options-header" title="Margin"></options-header>
                    <b-collapse id="options-letter-margin" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-value-unit v-model="settings.letter.style.margin" label="Margin" input-id="general-margin" placeholder="0.25in"/>
                      </b-card-body>
                    </b-collapse>

                    <!-- Padding Options -->
                    <options-header v-b-toggle.options-letter-padding header-class="options-header" title="Padding"></options-header>
                    <b-collapse id="options-letter-padding" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.letter.style.paddingTop" label="Top" input-id="certificate-padding-top" placeholder="unset"/>
                          <option-value-unit v-model="settings.letter.style.paddingBottom" label="Bottom" input-id="certificate-padding-bottom" placeholder="unset"/>
                          <option-value-unit v-model="settings.letter.style.paddingLeft" label="Left" input-id="certificate-padding-left" placeholder="unset"/>
                          <option-value-unit v-model="settings.letter.style.paddingRight" label="Right" input-id="certificate-padding-right" placeholder="unset"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>

                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Header -->
              <b-card no-body class="mb-0">
                <options-header v-b-toggle.options-header v-b-hover="hoverHeader" header-class="options-header" title="Header"></options-header>
                <b-collapse id="options-header" role="tabpanel" class="options-collapse">
                  <b-card-body body-bg-variant="light-primary">

                    <!-- Margin Options -->
                    <options-header v-b-toggle.options-header-margin header-class="options-header" title="Margin"></options-header>
                    <b-collapse id="options-header-margin" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.header.style.marginTop" label="Top" input-id="header-margin-top" placeholder="0.875in"/>
                          <option-value-unit v-model="settings.header.style.marginLeft" label="Left" input-id="header-margin-left" placeholder="0.875in"/>
                          <option-value-unit v-model="settings.header.style.marginRight" label="Right" input-id="header-margin-right" placeholder="0.875in"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>

                    <!-- Padding Options -->
                    <options-header v-b-toggle.options-header-padding header-class="options-header" title="Padding"></options-header>
                    <b-collapse id="options-header-padding" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.header.style.paddingTop" label="Top" input-id="header-padding-top" placeholder="unset"/>
                          <option-value-unit v-model="settings.header.style.paddingBottom" label="Bottom" input-id="header-padding-bottom" placeholder="unset"/>
                          <option-value-unit v-model="settings.header.style.paddingLeft" label="Left" input-id="header-padding-left" placeholder="unset"/>
                          <option-value-unit v-model="settings.header.style.paddingRight" label="Right" input-id="header-padding-right" placeholder="unset"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>

                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Logo -->
              <b-card no-body class="mb-0">
                <options-header v-b-toggle.options-logo v-b-hover="hoverLogo" header-class="options-header" title="Logo"></options-header>
                <b-collapse id="options-logo" role="tabpanel" class="options-collapse">
                  <b-card-body body-bg-variant="light-primary">

                    <!-- Image Options -->
                    <options-header v-b-toggle.options-logo-image header-class="options-header" title="Image"></options-header>
                    <b-collapse id="options-logo-image" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-image v-model="settings.logo.src" label="Url" input-id="logo-src"/>
                        <option-select v-model="settings.logo.style.objectFit" :options="options.fit" label="Fit" input-id="logo-fit" placeholder="cover"/>
                      </b-card-body>
                    </b-collapse>

                    <!-- Border Options -->
                    <options-header v-b-toggle.options-logo-border header-class="options-header" title="Border"></options-header>
                    <b-collapse id="options-logo-border" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-color v-model="settings.logo.style.borderColor" label="Border Color" input-id="logo-border-color" placeholder="unset"/>
                        <option-value-unit v-model="settings.logo.style.borderWidth" label="Border Width" input-id="logo-border-width" placeholder="unset"/>
                        <option-select v-model="settings.logo.style.borderStyle" :options="options.borderStyle" label="Border Style" input-id="logo-border-style" placeholder="unset"/>
                        <option-select v-model="settings.logo.style.borderRadius" :options="options.radius" label="Border Radius" input-id="logo-radius" placeholder="round"/>
                      </b-card-body>
                    </b-collapse>

                    <!-- Dimensions Options -->
                    <options-header v-b-toggle.options-logo-dimensions header-class="options-header" title="Dimensions"></options-header>
                    <b-collapse id="options-logo-dimensions" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.logo.style.width" label="Width" input-id="logo-width" placeholder="1.5in"/>
                          <option-value-unit v-model="settings.logo.style.height" label="Height" input-id="logo-height" placeholder="1.5in"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>

                    <!-- Margin Options -->
                    <options-header v-b-toggle.options-logo-margin header-class="options-header" title="Margin"></options-header>
                    <b-collapse id="options-logo-margin" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.logo.style.marginTop" label="Top" input-id="logo-margin-top" placeholder="1rem"/>
                          <option-value-unit v-model="settings.logo.style.marginBottom" label="Bottom" input-id="logo-margin-bottom" placeholder="1rem"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>


                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Return Address -->
              <b-card no-body class="mb-0">
                <options-header v-b-toggle.options-return-address v-b-hover="hoverReturnAddress" header-class="options-header" title="Return Address"></options-header>
                <b-collapse id="options-return-address" role="tabpanel" class="options-collapse">
                  <b-card-body body-bg-variant="light-primary">

                    <!-- Text Options -->
                    <options-header v-b-toggle.options-return-address-text header-class="options-header" title="Text"></options-header>
                    <b-collapse id="options-return-address-text" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-value v-model="settings.returnAddress.text.line1" input-id="return-address-text-line1" label="Line 1" />
                        <option-value v-model="settings.returnAddress.text.line2" input-id="return-address-text-line2" label="Line 2" />
                        <option-value v-model="settings.returnAddress.text.line3" input-id="return-address-text-line3" label="Line 3" />
                        <option-value v-model="settings.returnAddress.text.line4" input-id="return-address-text-line4" label="Line 4" />
                        <option-value v-model="settings.returnAddress.text.line5" input-id="return-address-text-line5" label="Line 5" />
                      </b-card-body>
                    </b-collapse>

                    <!-- Font Options -->
                    <options-header v-b-toggle.options-return-address-font header-class="options-header" title="Font"></options-header>
                    <b-collapse id="options-return-address-font" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-color v-model="settings.returnAddress.style.color" label="Font Color" input-id="return-address-color"/>
                        <option-value-unit v-model="settings.returnAddress.style.fontSize" label="Font Size" input-id="return-address-font-size" placeholder="1.5rem"/>
                        <option-select v-model="settings.returnAddress.style.fontFamily" label="Font Family" input-id="return-address-font-family" :options="options.fontFamily" placeholder="Montserrat, Helvetica, Arial, serif"/>
                        <option-select v-model="settings.returnAddress.style.fontWeight" label="Font Weight" input-id="return-address-font-weight" :options="options.fontWeight" placeholder="unset"/>
                      </b-card-body>
                    </b-collapse>


                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Recipient Address -->
              <b-card no-body class="mb-0">
                <options-header v-b-toggle.options-recipient-address v-b-hover="hoverRecipientAddress" header-class="options-header" title="Recipient Address"></options-header>
                <b-collapse id="options-recipient-address" role="tabpanel" class="options-collapse">
                  <b-card-body body-bg-variant="light-primary">

                    <!-- Text Options -->
                    <options-header v-b-toggle.options-recipient-address-text header-class="options-header" title="Text"></options-header>
                    <b-collapse id="options-recipient-address-text" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-value :value="'{{ student.name.* }}'" disabled input-id="recipient-address-text-recipient" label="Line 1" />
                        <option-value :value="'{{ student.address.line1 }}, {{ student.address.line2 }}'" text-area disabled input-id="recipient-address-text-line1" label="Line 2" />
                        <option-value :value="'{{ student.address.city }}, {{ student.address.state }} {{ student.address.zip }}'" text-area disabled input-id="recipient-address-text-line2" label="Line 3" />
                      </b-card-body>
                    </b-collapse>

                    <!-- Font Options -->
                    <options-header v-b-toggle.options-recipient-address-font header-class="options-header" title="Font"></options-header>
                    <b-collapse id="options-recipient-address-font" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-color v-model="settings.recipientAddress.style.color" label="Font Color" input-id="header-color"/>
                        <option-value-unit v-model="settings.recipientAddress.style.fontSize" label="Font Size" input-id="recipient-address-font-size" placeholder="1.5rem"/>
                        <option-select v-model="settings.recipientAddress.style.fontFamily" label="Font Family" input-id="recipient-address-font-family" :options="options.fontFamily" placeholder="Montserrat, Helvetica, Arial, serif"/>
                        <option-select v-model="settings.recipientAddress.style.fontWeight" label="Font Weight" input-id="recipient-address-font-weight" :options="options.fontWeight" placeholder="unset"/>
                      </b-card-body>
                    </b-collapse>


                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Body -->
              <b-card no-body class="mb-0">
                <options-header v-b-toggle.options-body v-b-hover="hoverBody" header-class="options-header" title="Body"></options-header>
                <b-collapse id="options-body" role="tabpanel" class="options-collapse">
                  <b-card-body body-bg-variant="light-primary">

                    <!-- Font Options -->
                    <options-header v-b-toggle.options-body-font header-class="options-header" title="Font"></options-header>
                    <b-collapse id="options-body-font" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-color v-model="settings.body.style.color" label="Font Color" input-id="body-color"/>
                        <option-value-unit v-model="settings.body.style.fontSize" label="Font Size" input-id="recipient-address-font-size" placeholder="1.5rem"/>
                        <option-select v-model="settings.body.style.fontFamily" label="Font Family" input-id="body-font-family" :options="options.fontFamily" placeholder="Montserrat, Helvetica, Arial, serif"/>
                        <option-select v-model="settings.body.style.fontWeight" label="Font Weight" input-id="body-font-weight" :options="options.fontWeight" placeholder="unset"/>
                      </b-card-body>
                    </b-collapse>

                    <!-- Margin Options -->
                    <options-header v-b-toggle.options-body-margin header-class="options-header" title="Margin"></options-header>
                    <b-collapse id="options-body-margin" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.body.style.marginTop" label="Top" input-id="body-margin-top" placeholder="2in"/>
                          <option-value-unit v-model="settings.body.style.marginLeft" label="Left" input-id="body-margin-left" placeholder="0.875in"/>
                          <option-value-unit v-model="settings.body.style.marginRight" label="Right" input-id="body-margin-right" placeholder="0.875in"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>

                    <!-- Padding Options -->
                    <options-header v-b-toggle.options-body-padding header-class="options-header" title="Padding"></options-header>
                    <b-collapse id="options-body-padding" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.body.style.paddingTop" label="Top" input-id="body-padding-top" placeholder="unset"/>
                          <option-value-unit v-model="settings.body.style.paddingBottom" label="Bottom" input-id="body-padding-bottom" placeholder="unset"/>
                          <option-value-unit v-model="settings.body.style.paddingLeft" label="Left" input-id="body-padding-left" placeholder="unset"/>
                          <option-value-unit v-model="settings.body.style.paddingRight" label="Right" input-id="body-padding-right" placeholder="unset"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>

                    <hr class="options-divider">

                    <!-- Left Options -->
                    <options-header v-b-toggle.options-body-signature header-class="options-header" title="Signature"></options-header>
                    <b-collapse id="options-body-signature" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white" class="p-50">
                        <b-form-group label="Text" label-for="options-body-signature-text-input" label-class="font-weight-bold font-small-3">
                          <b-form-input id="options-body-signature-text-input" v-model="settings.body.signature.text" />
                        </b-form-group>
                        <options-header v-b-toggle.options-body-signature-font header-class="options-header" title="Font"></options-header>
                        <b-collapse id="options-body-signature-font" role="tabpanel" class="options-collapse" visible>
                          <b-card-body body-bg-variant="white">
                            <option-value-unit v-model="settings.body.signature.style.fontSize" label="Font Size" input-id="body-signature-font-size" placeholder="2rem"/>
                            <option-select v-model="settings.body.signature.style.fontFamily" label="Font Family" input-id="body-signature-font-family" :options="options.fontFamily" placeholder="Montserrat"/>
                            <option-select v-model="settings.body.signature.style.fontWeight" label="Font Weight" input-id="body-signature-font-weight" :options="options.fontWeight" placeholder="unset"/>
                          </b-card-body>
                        </b-collapse>
                      </b-card-body>
                    </b-collapse>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Footer -->
              <b-card no-body class="mb-0">
                <options-header v-b-toggle.options-footer v-b-hover="hoverFooter" header-class="options-header" title="Footer"></options-header>
                <b-collapse id="options-footer" role="tabpanel" class="options-collapse">
                  <b-card-body body-bg-variant="light-primary">

                    <!-- Layout Options -->
                    <options-header v-b-toggle.options-footer-layout header-class="options-header" title="Layout"></options-header>
                    <b-collapse id="options-footer-layout" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <option-select v-model="settings.footer.style.display" :options="options.display" label="Display" input-id="footer-display" placeholder="flex"/>
                        <option-select v-model="settings.footer.style.justifyContent" :options="options.justify" label="Justify" input-id="footer-justify" placeholder="space between"/>
                        <option-select v-model="settings.footer.style.alignItems" label="Vertical Alignment" input-id="footer-right-line1-weight" :options="options.align" placeholder="center"/>
                      </b-card-body>
                    </b-collapse>

                    <!-- Padding Options -->
                    <options-header v-b-toggle.options-footer-padding header-class="options-header" title="Padding"></options-header>
                    <b-collapse id="options-footer-padding" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <options-sync-container>
                          <option-value-unit v-model="settings.footer.style.paddingTop" label="Top" input-id="footer-padding-top" placeholder="1rem"/>
                          <option-value-unit v-model="settings.footer.style.paddingBottom" label="Bottom" input-id="footer-padding-bottom" placeholder="1rem"/>
                          <option-value-unit v-model="settings.footer.style.paddingLeft" label="Left" input-id="footer-padding-left" placeholder="1rem"/>
                          <option-value-unit v-model="settings.footer.style.paddingRight" label="Right" input-id="footer-padding-right" placeholder="1rem"/>
                        </options-sync-container>
                      </b-card-body>
                    </b-collapse>

                    <hr class="options-divider">

                    <!-- Left Options -->
                    <!-- Text Options -->
                    <!-- Line 1 -->
                    <options-header v-b-toggle.options-footer-line1 header-class="options-header" title="Line 1"></options-header>
                    <b-collapse id="options-footer-line1" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <b-form-group label="Text" label-for="footer-line1-text" label-class="font-weight-bold font-small-3">
                          <b-form-textarea id="footer-line1-text" v-model="settings.footer.line1.text" rows="3" max-rows="3" no-resize />
                        </b-form-group>
                        <options-header v-b-toggle.options-footer-line1-font header-class="options-header" title="Font"></options-header>
                        <b-collapse id="options-footer-line1-font" role="tabpanel" class="options-collapse">
                          <b-card-body body-bg-variant="white">
                            <option-value-unit v-model="settings.footer.line1.style.fontSize" label="Font Size" input-id="footer-line1-font-size" placeholder="2rem"/>
                            <option-select v-model="settings.footer.line1.style.fontFamily" label="Font Family" input-id="footer-line1-font-family" :options="options.fontFamily" placeholder="Montserrat"/>
                            <option-select v-model="settings.footer.line1.style.fontWeight" label="Font Weight" input-id="footer-line1-weight" :options="options.fontWeight" placeholder="unset"/>
                          </b-card-body>
                        </b-collapse>
                      </b-card-body>
                    </b-collapse>

                    <!-- Line 2 -->
                    <options-header v-b-toggle.options-footer-line2 header-class="options-header" title="Line 2"></options-header>
                    <b-collapse id="options-footer-line2" role="tabpanel" class="options-collapse">
                      <b-card-body body-bg-variant="white">
                        <b-form-group label="Text" label-for="footer-line2-text" label-class="font-weight-bold font-small-3">
                          <b-form-textarea id="footer-line2-text" v-model="settings.footer.line2.text" rows="3" max-rows="3" no-resize />
                        </b-form-group>
                        <options-header v-b-toggle.options-footer-line2-font header-class="options-header" title="Font"></options-header>
                        <b-collapse id="options-footer-line2-font" role="tabpanel" class="options-collapse">
                          <b-card-body body-bg-variant="white">
                            <option-value-unit v-model="settings.footer.line2.style.fontSize" label="Font Size" input-id="footer-line2-font-size" placeholder="2rem"/>
                            <option-select v-model="settings.footer.line2.style.fontFamily" label="Font Family" input-id="footer-line2-font-family" :options="options.fontFamily" placeholder="Montserrat"/>
                            <option-select v-model="settings.footer.line2.style.fontWeight" label="Font Weight" input-id="footer-line2-font-weight" :options="options.fontWeight" placeholder="unset"/>
                          </b-card-body>
                        </b-collapse>
                      </b-card-body>
                    </b-collapse>

                    <!-- Footer Nested Options -->

                  </b-card-body>
                </b-collapse>
              </b-card>
            </settings-container>

            <!-- Code -->
            <b-card-actions title="Code" action-collapse :collapsed="false" header-class="py-1">
              <p class="font-small-3">Use any of the following code snippets in the body text to render those values.</p>
              <div>
                <b-button v-for="(code, index) in codes.student" :key="`code-student-${index}`"
                          :variant="code.variant ? code.variant : 'outline-primary'" size="sm" pill
                          title="Click To Copy"
                          class="mr-25 mb-25 p-50"
                          @click="copyCode(code.value)">
                  {{ code.label }}
                </b-button>
                <hr/>
                <b-button v-for="(code, index) in codes.chairs" :key="`code-chairs-${index}`"
                          :variant="code.variant ? code.variant : 'outline-primary'" size="sm" pill
                          title="Click To Copy"
                          class="mr-25 mb-25 p-50"
                          @click="copyCode(code.value)">
                  {{ code.label }}
                </b-button>
                <hr/>
                <b-button v-for="(code, index) in codes.general" :key="`code-general-${index}`"
                          :variant="code.variant ? code.variant : 'outline-primary'" size="sm" pill
                          title="Click To Copy"
                          class="mr-25 mb-25 p-50"
                          @click="copyCode(code.value)">
                  {{ code.label }}
                </b-button>
              </div>
            </b-card-actions>
          </template>

					<!-- Filters & Paging -->
          <template v-else>
            <!-- Filters -->
            <b-card-actions title="Filters" action-collapse card-class="mb-1" header-class="py-50">

              <b-form-group label="Ensemble" label-for="ensembles">
                <v-select id="ensembles"
                          v-model="filters.ensembles.value"
                          :options="filters.ensembles.items"
                          :loading="filters.ensembles.loading"
                          :reduce="option => option.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="font-small-3">
                  <template #option="{ label, count }">
                    <div class="d-flex justify-content-between">
                      <span> {{ label }} </span>
                      <b-badge size="sm" variant="light-primary" title="Letters">{{ count }}</b-badge>
                    </div>
                  </template>
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                </v-select>
              </b-form-group>

              <b-form-group label="Instruments" label-for="ensembles">
                <v-select id="ensembles"
                          v-model="filters.instruments.value"
                          :options="filters.instruments.items"
                          :loading="filters.instruments.loading"
                          :reduce="option => option.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="font-small-3">
                  <template #option="{ label, count }">
                    <div class="d-flex justify-content-between">
                      <span> {{ label }} </span>
                      <b-badge size="sm" variant="light-primary" title="Letters">{{ count }}</b-badge>
                    </div>
                  </template>
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                </v-select>
              </b-form-group>

              <b-form-group label="School" label-for="schools">
                <v-select id="schools"
                          v-model="filters.schools.value"
                          :options="filters.schools.items"
                          :loading="filters.schools.loading"
                          :reduce="option => option.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="font-small-3">
                  <template #option="{ label, count }">
                    <div class="d-flex justify-content-between">
                      <span> {{ label }} </span>
                      <b-badge size="sm" variant="light-primary" title="Letters">{{ count }}</b-badge>
                    </div>
                  </template>
                  <template #selected-option="{ label, }">
                    {{ label }}
                  </template>
                </v-select>
              </b-form-group>
            </b-card-actions>
            <!-- Sorting -->
            <b-card-actions title="Sorting" action-collapse card-class="mb-1" header-class="py-50">
              <b-form-group label="Sort By" label-for="sorting-by">
                <b-input-group class="flex-nowrap">
                  <template v-if="sorting.drag">
                    <draggable v-model="sorting.by" class="p-50 flex-grow-1 rounded-left sorting-btn-border">
                      <b-badge v-for="(item, index) in sorting.by" :key="`sorting-by-${index}`" variant="light-primary" class="mr-50 font-small-3 font-weight-normal cursor-move">
                        {{ sorting.options.find(option => option.value === item).label }}
                        <font-awesome-icon :icon="['fas', 'grip-vertical']" class="my-auto ml-25"/>
                      </b-badge>
                    </draggable>
                  </template>
                  <template v-else>
                    <v-select v-model="sorting.by" multiple
                              :options="sorting.options" label="label"
                              :reduce="option => option.value"
                              input-id="sorting-by" class="h-100"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"/>
                  </template>

                  <b-input-group-append>
                    <b-button v-if="sorting.by.length > 1" size="sm" variant="white" class="sorting-btn-border border-left-0" title="Selected Order" @click="sorting.drag = !sorting.drag" >
                      <font-awesome-icon :icon="['fas', 'grip-vertical']" />
                    </b-button>
                    <b-button size="sm" variant="white" class="sorting-btn-border" title="Sort Order" @click="sorting.desc = !sorting.desc">
                      <font-awesome-icon :icon="['fas', sorting.desc ? 'sort-alpha-down-alt' : 'sort-alpha-down']" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-card-actions>
            <!-- Paging -->
            <b-card-actions title="Paging" action-collapse card-class="mb-1" header-class="py-50">
              <b-form-group label="Page Size" label-for="paging-size">
                <v-select id="paging-size"
                          v-model="paging.size"
                          :options="paging.sizes"
                          :reduce="option => option.value"
                          :clearable="false"
                          append-to-body/>
              </b-form-group>

              <b-pagination v-if="paging.size !== -1"
                            id="paging-page"
                            v-model="paging.page"
                            :total-rows="paging.total"
                            :per-page="paging.size"
                            first-number
                            last-number
                            :limit="3"
                            pills align="fill"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="14"/>
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="14"/>
                </template>
              </b-pagination>
            </b-card-actions>
            <!-- Options -->
            <b-card-actions title="Options" action-collapse collapsed card-class="mb-1" header-class="py-50">
              <b-row>
                <b-col cols="6" align-self="center">
                  <label for="marks-page">Page Marks</label>
                </b-col>
                <b-col cols="6" align-self="center" class="d-flex justify-content-end">
                  <b-checkbox id="marks-page" v-model="marks.page" switch inline size="sm"/>
                </b-col>
                <b-col cols="6" align-self="center">
                  <label for="marks-fold">Fold Marks</label>
                </b-col>
                <b-col cols="6" align-self="center" class="d-flex justify-content-end">
                  <b-checkbox id="marks-fold" v-model="marks.fold" switch inline size="sm"/>
                </b-col>
              </b-row>
              <!--              <b-form-group label="Page Markers" label-for="schools">
                              <b-checkbox v-model="marks.page" switch inline>
                                <span class="align-middle">Show Page Markers</span>
                              </b-checkbox>
                            </b-form-group>
                            <b-form-group label="Fold Markers" label-for="schools">
                              <b-checkbox v-model="marks.fold" switch inline>
                                <span class="align-middle">Show Page Markers</span>
                              </b-checkbox>
                            </b-form-group>-->
            </b-card-actions>
            <!-- Print -->
            <b-button block variant="primary" @click="printWindow()">
              <font-awesome-icon icon="fa-solid fa-print" />
              <span class="align-middle ml-50">Print</span>
            </b-button>
          </template>
				</b-col>
				<b-col class="letter-col-content">
          <!-- Alerts -->
          <b-alert :show="hasChanges" variant="danger" class="d-print-none">
            <div class="d-flex">
              <b-icon icon="exclamation-triangle-fill" font-scale="1" class="mt-25 mr-25"/>
              <div class="align-start ml-50 font-small-3">
                <strong class="d-block">Unsaved Changed!</strong>
                <span>Don't forget to save your changes before leaving the page.</span>
              </div>
            </div>
          </b-alert>
          <b-alert :show="scaler.width <= 1440 && alert.scaling.show" variant="primary" class="d-print-none" dismissible @dismissed="alert.scaling.show = false">
            <b-icon icon="exclamation-triangle-fill" font-scale="1"></b-icon>
            <span class="align-middle ml-50 font-small-3">
              {{ alert.scaling.message }}
            </span>
          </b-alert>
          <b-alert :show="state.editing && alert.editing.show" variant="primary" class="d-print-none" dismissible @dismissed="alert.editing.show = false">
            <b-icon icon="exclamation-triangle-fill" font-scale="1"></b-icon>
            <span class="align-middle ml-50 font-small-3">
              {{ alert.editing.message }}
            </span>
          </b-alert>

					<!-- Content -->
					<b-overlay :show="students.loading || chairs.loading|| ensembles.loading" variant="white" opacity="1" :rounded="true" :class="state.editing ? 'd-flex flex-row flex-wrap' : undefined">
            <template #overlay>
              <div class="d-flex align-items-center flex-column">
                <b-spinner small label="Loading..."></b-spinner>
                <small class="mt-50">Loading...</small>
              </div>
            </template>
            <template #default>
              <template v-if="students.loading || chairs.loading || ensembles.loading">
                <b-card><b-card-body></b-card-body></b-card>
              </template>
              <template v-else>
                <b-alert v-if="students.items.length === 0" show variant="danger" class="w-100 d-print-none">
                  <b-icon icon="exclamation-triangle-fill" font-scale="1" class="mr-50"></b-icon>
                  <span class="align-middle ml-50 font-small-3">
                    No selections were found.
                  </span>
                </b-alert>
                <b-alert v-if="chairs.items.length === 0" show variant="danger" class="w-100 d-print-none">
                  <b-icon icon="exclamation-triangle-fill" font-scale="1" class="mr-50"></b-icon>
                  <span class="align-middle ml-50 font-small-3">
                    No Ensemble Chairs were found.
                  </span>
                </b-alert>
                <b-alert v-else-if="!filteredItems.length && students.items.length" show variant="primary" class="w-100 d-print-none">
                  <b-icon icon="info-circle-fill" font-scale="1"></b-icon>
                  <span class="align-middle ml-50 font-small-3">No selections found for filters.</span>
                </b-alert>
                <template v-else>
                  <template v-if="state.editing">
                    <div class="letter-container-editor">
                      <letter v-for="(student, index) in filteredItems.slice(0, 1)" :key="`letter-edit-${index}`"
                              :editing="true"
                              :settings="settings"
                              :student="student"
                              :chairs="chairs.items"
                              :hover-letter="hover.letter"
                              :hover-header="hover.header"
                              :hover-logo="hover.logo"
                              :hover-return-address="hover.returnAddress"
                              :hover-recipient-address="hover.recipientAddress"
                              :hover-body="hover.body"
                              :show-fold-marks="marks.fold"
                              :show-page-marks="marks.page"
                              class="letter-editor"
                      />
                      <template v-if="scaler.width >= 2500">
                        <letter v-for="(student, index) in filteredItems.slice(0, 1)" :key="`letter-edit-preview-${index}`"
                                :editing="false"
                                :settings="settings"
                                :student="student"
                                :chairs="chairs.items"
                                :hover-letter="hover.letter"
                                :hover-header="hover.header"
                                :hover-logo="hover.logo"
                                :hover-return-address="hover.returnAddress"
                                :hover-recipient-address="hover.recipientAddress"
                                :hover-body="hover.body"
                                :show-fold-marks="marks.fold"
                                :show-page-marks="marks.page"
                                class="letter-editor"/>
                      </template>

                    </div>
                  </template>
                  <template v-else>
                    <div class="letter-container">
                      <letter v-for="(student, index) in filteredItems" :key="`letter-preview-${index}`"
                              :settings="settings"
                              :student="student"
                              :chairs="chairs.items"
                              :show-fold-marks="marks.fold"
                              :show-page-marks="marks.page"
                      />
                    </div>

                    <div class="d-flex align-items-center justify-content-center d-print-none w-100">
                      <span v-if="students.items.length > 0" class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.of }} Letters</span>
                      <span v-else class="text-muted">Showing 0 of {{ pagination.of }} Letters</span>
                    </div>
                  </template>
                </template>
              </template>
            </template>
					</b-overlay>
				</b-col>
			</b-row>
		</template>

		<template #debug>
			<b-row>
				<b-col cols="6">
					<debug>
						{{ settings }}
					</debug>
				</b-col>
        <b-col cols="6">
          <debug>
            {{ students }}
          </debug>
        </b-col>
			</b-row>
		</template>
	</page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import vSelect from 'vue-select'
import Fuse from 'fuse.js';
import {API, graphqlOperation} from 'aws-amplify';
import { listSelections, getUser } from './student-letter';
import Letter from './StudentLetter.vue';
import _ from 'lodash';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import SettingsContainer from '../settings/SettingsContainer.vue';
import OptionsSyncContainer from '../settings/OptionsSyncContainer.vue';
import OptionsHeader from '../settings/OptionsHeader.vue';
import OptionColor from '../settings/OptionColor.vue';
import OptionImage from '../settings/OptionImage.vue';
import OptionSelect from '../settings/OptionSelect.vue';
import OptionValue from '../settings/OptionValue.vue';
import OptionValueUnit from '../settings/OptionValueUnit.vue';

import printableSettingsMixin from '../settings/settings.mixin';
import printMixin from '../print.mixin';
import settingsMixin from '@/mixins/settings.mixin';
import OverlayLoading from '@/components/OverlayLoading.vue';
import draggable from 'vuedraggable';
import {adminListUsersInGroup} from '@/scripts/aws';
import {listEnsembles} from '@/views/printable/letters/school-letter';

export default {
	components: {
    OverlayLoading,
		SettingsContainer,
		PageLayout,
		Letter,
		OptionSelect,
		OptionValue,
		OptionValueUnit,
		OptionColor,
		OptionImage,
		OptionsHeader,
		OptionsSyncContainer,
		BCardActions,
		vSelect,
    draggable
	},
	mixins: [ printableSettingsMixin, printMixin, settingsMixin ],
	data() {
		return {
			key: 'letter-student',
			settings: {
				letter: {
					style: {
            color: null,
            fontFamily: null,
            fontSize: {
              value: null,
              unit: null
            },
            fontWeight: null,
						borderColor: null,
						borderStyle: null,
						borderWidth: {
							value: null,
							unit: null
						},
						width: null,
						height: null,
						margin: {
							value: null,
							unit: null
						},
						paddingTop: {
							value: null,
							unit: null
						},
						paddingBottom: {
							value: null,
							unit: null
						},
						paddingLeft: {
							value: null,
							unit: null
						},
						paddingRight: {
							value: null,
							unit: null
						},
					},
				},
				header: {
					style: {
						color: null,
						fontSize: {
							value: null,
							unit: null
						},
						fontFamily: null,
						fontWeight: null,
						marginTop: {
							value: null,
							unit: null
						},
						marginLeft: {
							value: null,
							unit: null
						},
						marginRight: {
							value: null,
							unit: null
						},
						paddingTop: {
							value: null,
							unit: null
						},
						paddingBottom: {
							value: null,
							unit: null
						},
						paddingLeft: {
							value: null,
							unit: null
						},
						paddingRight: {
							value: null,
							unit: null
						},
					},
				},
				logo: {
					src: null,
					style: {
						width: {
							value: null,
							unit: null
						},
						height: {
							value: null,
							unit: null
						},
						borderWidth: {
							value: null,
							unit: null
						},
						borderStyle: null,
						borderColor: null,
						borderRadius: null,
						objectFit: null,
						marginTop: {
							value: null,
							unit: null
						},
						marginBottom: {
							value: null,
							unit: null
						},
					}
				},
				returnAddress: {
					style: {
						color: null,
						fontFamily: null,
						fontSize: {
							value: null,
							unit: null
						},
						fontWeight: null,
					},
					text: {
						line1: 'New York State School Music Association',
						line2: '718 The Plain Road',
						line3: 'Westbury, NY 11590-5931',
						line4: 'Phone: (516) 997-7200',
						line5: 'Fax: (516) 997-1700',
					},
				},
				recipientAddress: {
					style: {
						color: null,
						fontFamily: null,
						fontSize: {
							value: null,
							unit: null
						},
						fontWeight: null,
					}
				},
				body: {
					style: {
						color: null,
						fontFamily: null,
						fontSize: {
							value: null,
							unit: null
						},
						fontWeight: null,
						marginTop: {
							value: null,
							unit: null
						},
						marginLeft: {
							value: null,
							unit: null
						},
						marginRight: {
							value: null,
							unit: null
						},
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
						paddingLeft: {
							value: null,
							unit: null
						},
						paddingRight: {
							value: null,
							unit: null
						},
					},
					text: `
            <p>Dear {{ student.name.first }},</p>
            <p>
              Congratulations! You have been invited to participate in the {{ year }} NYSSMA Conference All-State
              {{ student.selection.ensemble.name }} on {{ student.selection.instrument.name }} {{ student.selection.part }} from December 5-8 in Rochester, NY. Your ensemble Chair
              will contact you via email regarding the acceptance process, paperwork, and additional information. If
              you do not receive an email by September 10, email your ensemble chairperson from a non-school email
              address using the contact information listed below
            </p>
            <div class="d-flex justify-content-between mt-2">
              <p style="max-height: 200px;">
                Respectfully,<br/>
                Daryle Redmond
              </p>
            </p>
            <div class="d-flex justify-content-end mt-2">
              <p>
                <u>Ensemble Chair</u><br/>
                {{ chair.name.full }}<br/>
                {{ chair.phone}}<br/>
                {{ chair.email }}
              </p>
            </p>
					`,
          signature: {
            style: {
              color: null,
              fontFamily: 'Freehand',
              fontSize: {
                value: 1,
                unit: 'rem'
              },
              fontWeight: null,
            },
            text: 'Daryle Redmond',
          }
				},
        footer: {
          style: {
            display: null,
            justifyContent: null,
            alignItems: null,
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
          },
          line1: {
            text: 'Performance',
            style: {
              color: null,
              fontFamily: null,
              fontSize: {
                value: null,
                unit: null
              },
              fontWeight: null,
            }
          },
          line2: {
            text: `All-State ${new Date().getFullYear()}`,
            style: {
              color: null,
              fontFamily: null,
              fontSize: {
                value: null,
                unit: null
              },
              fontWeight: null,
            }
          },
        },
			},
			options: {
				borderStyle: [
					{ value: 'none', label: 'None'},
					{ value: 'solid', label: 'Solid'},
					{ value: 'dashed', label: 'Dashed'},
					{ value: 'dotted', label: 'Dotted'},
					{ value: 'double', label: 'Double'},
					{ value: 'groove', label: 'Groove'},
					{ value: 'ridge', label: 'Ridge'},
					{ value: 'inset', label: 'Inset'},
					{ value: 'outset', label: 'Outset'},
					{ value: 'hidden', label: 'Hidden'},
				],
				display: [
					{ value: 'flex', label: 'Flex' },
					{ value: 'block', label: 'Block' },
					{ value: 'inline-block', label: 'Inline Block' },
					{ value: 'inline', label: 'Inline' },
					{ value: 'none', label: 'None' },
				],
        fontFamily: [
          { value: 'Arial', label: 'Arial' },
          { value: 'Georgia', label: 'Georgia' },
          { value: 'Times New Roman', label: 'Times New Roman' },
          { value: 'Courier New', label: 'Courier New' },
          { value: 'Montserrat', label: 'Montserrat' },
          { value: 'Helvetica', label: 'Helvetica' },
          { value: 'serif', label: 'Serif' },
          { value: 'Cedarville Cursive', label: 'Cedarville Cursive' },
          { value: 'Freehand', label: 'Freehand' },
          { value: 'Kristi', label: 'Kristi' },
          { value: 'La Belle Aurore', label: 'La Belle Aurore' },
          { value: 'Pinyon Script', label: 'Pinyon Script' },
          { value: 'Sacramento', label: 'Sacramento' },
          { value: 'Satisfy', label: 'Satisfy' },
        ],
				fontWeight: [
					{ value: '100', label: 'Thin' },
					{ value: '200', label: 'Extra Light' },
					{ value: '300', label: 'Light' },
					{ value: '400', label: 'Normal' },
					{ value: '500', label: 'Medium' },
					{ value: '600', label: 'Semi Bold' },
					{ value: '700', label: 'Bold' },
					{ value: '800', label: 'Extra Bold' },
					{ value: '900', label: 'Black' },
				],
				justify: [
					{ value: 'flex-start', label: 'Start' },
					{ value: 'flex-end', label: 'End' },
					{ value: 'center', label: 'Center' },
					{ value: 'space-between', label: 'Space Between' },
					{ value: 'space-around', label: 'Space Around' },
					{ value: 'space-evenly', label: 'Space Evenly' },
				],
				fit: [
					{ value: 'fill', label: 'Fill' },
					{ value: 'contain', label: 'Contain' },
					{ value: 'cover', label: 'Cover' },
					{ value: 'none', label: 'None' },
					{ value: 'scale-down', label: 'Scale Down' },
				],
				radius: [
					{ value: '50%', label: 'Round' },
					{ value: '0%', label: 'Square' }
				],
        studentFormat: [
          { value: 'fn-ln', label: 'Name' },
          { value: 'fn-ln-e', label: 'Name - Ensemble' },
          { value: 'fn-ln-i', label: 'Name - Instrument' },
          { value: 'fn-ln-e-p', label: 'Name - Ensemble - Part' },
          { value: 'fn-ln-e-i-p', label: 'Name - Ensemble - Instrument - Part' },
        ]
			},
      filters: {
        schools: {
          value: null,
          items: [],
          loading: true,
        },
        ensembles: {
          value: null,
          items: [],
          loading: true,
        },
        instruments: {
          value: null,
          items: [],
          loading: true,
        }
      },
      sorting: {
        by: ['name.first', 'name.last'],
        desc: false,
        drag: false,
        options: [
          { label: 'First Name', value: 'name.first'},
          { label: 'Last Name', value: 'name.last'},
        ]
      },
      paging: {
        total: 0,
        page: 1,
        size: 25,
        sizes: [
          { value: -1, label: 'All' },
          { value: 1, label: '1' },
          { value: 5, label: '5' },
          { value: 10, label: '10' },
          { value: 25, label: '25' },
          { value: 50, label: '50' },
          { value: 100, label: '100' }
        ]
      },
      codes: {
        general: [
          { label: '{{ year }}', value: '{{ year }}', variant: 'outline-primary' },
          { label: '{{ signature }}', value: '{{ signature }}', variant: 'outline-primary' },
        ],
        student: [
          { label: '{{ student.name.first }}', value: '{{ student.name.first }}', variant: 'outline-primary' },
          { label: '{{ student.name.last }}', value: '{{ student.name.last }}', variant: 'outline-primary' },
          { label: '{{ student.name.* }}', value: '{{ student.name.* }}', variant: 'outline-light-primary' },

          { label: '{{ student.address.line1 }}', value: '{{ student.address.line1 }}', variant: 'outline-primary' },
          { label: '{{ student.address.line2 }}', value: '{{ student.address.line2 }}', variant: 'outline-primary' },
          { label: '{{ student.address.city }}', value: '{{ student.address.city }}', variant: 'outline-primary' },
          { label: '{{ student.address.state }}', value: '{{ student.address.state }}', variant: 'outline-primary' },
          { label: '{{ student.address.zip }}', value: '{{ student.address.zip }}', variant: 'outline-primary' },
          { label: '{{ student.address.* }}', value: '{{ student.address.* }}', variant: 'outline-light-primary' },

          { label: '{{ student.selection.ensemble.name }}', value: '{{ student.selection.ensemble.name }}', variant: 'outline-primary' },
          { label: '{{ student.selection.instrument.name }}', value: '{{ student.selection.instrument.name }}', variant: 'outline-primary' },
          { label: '{{ student.selection.part }}', value: '{{ student.selection.part }}', variant: 'outline-primary' },
        ],
        chairs: [

          { label: '{{ chairs.[] }}', value: '{{ chairs.[{{ chair.name.full }} {{ chair.email}} {{ chair.phone }} {{ chair.address.* }} ]}}', variant: 'primary' },
          { label: '{{ chair.* }}', value: '{{ chair.* }}', variant: 'outline-light-primary' },
          { label: '{{ chair.name.first }}', value: '{{ chair.name.first }}', variant: 'outline-primary' },
          { label: '{{ chair.name.last }}', value: '{{ chair.name.last }}', variant: 'outline-primary' },
          { label: '{{ chair.name.* }}', value: '{{ chair.name.* }}', variant: 'outline-light-primary'},
          { label: '{{ chair.phone }}', value: '{{ chair.phone }}', variant: 'outline-primary' },
          { label: '{{ chair.email }}', value: '{{ chair.email }}', variant: 'outline-primary' },
          { label: '{{ chair.address.* }}', value: '{{ chair.address.* }}', variant: 'outline-light-primary' },
          { label: '{{ chairs.* }}', value: '{{ chairs.* }}', variant: 'outline-light-secondary' },
        ],
      },
      alert: {
        scaling: {
          show: true,
          message: 'The content has been scaled down to fit the page.',
        },
        editing: {
          show: true,
          message: 'While in edit mode, each configurable area will have a dashed border around it to help guide you.',
        },
      },
      hover: {
        letter: false,
        header: false,
        logo: false,
        returnAddress: false,
        recipientAddress: false,
        body: false,
        footer: false
      },
      marks: {
        fold: true,
        page: true
      },
      ensembles: {
        items: [],
        loading: true,
        loaded: 0
      },
			students: {
				items: [],
				loading: true,
        loaded: 0
			},
      chairs: {
        items: [],
        loading: true,
      },
		};
	},
	computed: {
		filteredItems() {
      if(this.students.loading) return []
			const fuse = new Fuse(this.students.items, {
				useExtendedSearch: true,
				threshold: 0.3,
				keys: [
					'selection.ensemble.id',
          'selection.instrument.id',
          'school.id',
				]
			})
			const query = {$and: []}
			if (this.filters.ensembles.value) {
				query.$and.push({ 'selection.ensemble.id': this.filters.ensembles.value})
			}
      if (this.filters.instruments.value) {
        query.$and.push({ 'selection.instrument.id': this.filters.instruments.value})
      }
      if (this.filters.schools.value) {
				query.$and.push({'school.id': this.filters.schools.value})
			}

			let {items} = this.students
			if (query.$and.length) {
				items = fuse.search(query).map(({item}) => item)
			}

      items = this.sortItems(items)

      if(this.paging.size >= 1) {
        this.paging.total = items.length // eslint-disable-line vue/no-side-effects-in-computed-properties
        const chunks = _.chunk(items, this.paging.size)
        return chunks[this.paging.page - 1] || []
      }
      return items
		},
    pagination() {
      let to = this.paging.size * (this.paging.page)
      if(this.paging.size === 0 || to >= this.paging.total) {
        to = this.paging.total
      }
      let from = 0
      if(this.paging.total > 0) {
        from = this.paging.size * (this.paging.page - 1) + 1
      }

      return {
        from: from,
        to: to,
        of: this.paging.total,
      }
    },
	},
	async mounted() {
    this.setScalerMaxWidth(1440)
    await this.loadSettings()
    await this.listEnsembles()
    await this.listChairs()
		await this.listSelections()
		this.$refs.layout.state.loading = false
	},
	methods: {
		async refresh() {
      this.ensembles.loaded = 0
      this.ensembles.loading = true
      this.chairs.loaded = 0
      this.chairs.loading = true
      this.students.loaded = 0
      this.students.loading = true

      this.filters.schools.loading = true
      this.filters.schools.value = null
      this.filters.ensembles.loading = true
      this.filters.ensembles.value = null
      this.filters.instruments.loading = true
      this.filters.instruments.value = null

      await this.loadSettings()
      await this.listEnsembles()
      await this.listChairs()
			await this.listSelections()
		},
    async loadSettings() {
      const settings = await this.getSettings(this.key)
      if(settings) {
        this.settings = this.deepMerge(this.settings, settings);
      }
      if(this.settings.logo.src === null) {
        // eslint-disable-next-line global-require
        this.settings.logo.src = require('@/assets/images/logo/logo5-blue-white-gold.svg')
      }
      this.initialSettings = _.cloneDeep(this.settings)
    },
    async listEnsembles() {
      const response = await API.graphql(graphqlOperation(listEnsembles));
      this.ensembles.items = response.data.listEnsembles.items;
      this.ensembles.loaded = this.ensembles.items.length;
      this.ensembles.loading = false;
    },
    async listChairs() {
      await adminListUsersInGroup('NYSSMA_Chair').then(async (chairs) => {
        this.chairs.items = chairs.map((chair) => ({
          id: chair.Attributes.find(attr => attr.Name === 'custom:user_id')?.Value,
          username: chair.Username,
          name: null,
          phone: chair.Attributes.find(attr => attr.Name === 'phone_number')?.Value,
          email: chair.Attributes.find(attr => attr.Name === 'email')?.Value,
          address: null,
          ensembleIds: [],
        }))
        await this.processChairsInChunks(5, 1000);
      })
      this.chairs.loading = false
    },
    async listSelections(nextToken, pagedSelections) {
      this.students.loading = true;
      const selections = pagedSelections || [];
      const input = {
        limit: 500,
        filter: {
          //accepted: { eq: true },
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
        nextToken: nextToken,
      }


      const response = await API.graphql(graphqlOperation(listSelections, input));
      selections.push(...response.data.listSelections.items.filter(selection => selection.ensemble && selection.ensemble?.code !== 'A'));

      this.students.loaded = selections.length;
      if (response.data.listSelections.nextToken) {
        await this.listSelections(response.data.listSelections.nextToken, selections);
      }
      else {
        this.students.items = selections.map((selection) => ({
          ...selection.application.student,
          selection: {
            id: selection.id,
            accepted: selection.accepted,
            part: selection.part,
            ensemble: this.ensembles.items.find(ensemble => ensemble.id === selection.ensemble.id),
            instrument: selection.application?.instrument
          }
        }))
        this.students.loading = false;

        this.filters.schools.items = _.uniqBy(this.students.items.filter(student => student?.school?.id).map(student => ({
          value: student?.school?.id,
          label: student?.school?.name?.legal,
          count: this.students.items.filter(s => s?.school?.id === student?.school?.id)?.length || 0
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        this.filters.schools.loading = false;

        this.filters.ensembles.items = _.uniqBy(this.students.items.filter(item => item.selection?.ensemble?.id).map(student => ({
          value: student?.selection?.ensemble?.id,
          label: `${student?.selection?.ensemble?.name}`,
          count: this.students.items.filter(s => s?.selection.ensemble.id === student?.selection.ensemble.id)?.length || 0
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        this.filters.ensembles.loading = false;

        this.filters.instruments.items = _.uniqBy(this.students.items.filter(item => item.selection?.instrument?.id).map(student => ({
          value: student?.selection?.instrument?.id,
          label: `${student?.selection?.instrument?.name}`,
          count: this.students.items.filter(item => item?.selection.instrument.id === student?.selection.instrument.id)?.length || 0
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        this.filters.instruments.loading = false;
      }
    },

    async getUserById(id) {
      const response = await API.graphql(graphqlOperation(getUser, { id }))
      return response.data.getUser;
    },

    async delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async processChairsInChunks(chunkSize, delayTime) {
      const { chairs, ensembles } = this
      for (let i = 0; i < chairs.items.length; i += chunkSize) {
        const chunk = chairs.items.slice(i, i + chunkSize);
        // eslint-disable-next-line no-await-in-loop
        await Promise.all(chunk.map(async (chair) => {
          const user = await this.getUserById(chair.id);
          if(user) {
            chair.name = user.name;
            chair.address = user.address;
            chair.ensembleIds = user.ensembleIds; //ensembles.items.filter(ensemble => ensemble.chairId === user.id).map(ensemble => ensemble.id)
          }
        }));

        if (i + chunkSize < chairs.items.length) {
          // eslint-disable-next-line no-await-in-loop
          await this.delay(delayTime);
        }
      }
    },


    /** Sort **/
    sortItems(items) {
      const { by, desc } = this.sorting;
      const order = desc ? 'desc' : 'asc';
      const orders = []; // Array to hold the order (asc/desc) for each criteria

      for (let i = 0; i < by.length; i++) {
        orders.push(order);
      }

      return _.orderBy(items, by, orders);
    },

    /** Hover **/
    hoverLetter(isHovered) {
      this.hover.letter = isHovered
    },
    hoverHeader(isHovered) {
      this.hover.header = isHovered
    },
    hoverLogo(isHovered) {
      this.hover.logo = isHovered
    },
    hoverReturnAddress(isHovered) {
      this.hover.returnAddress = isHovered
    },
    hoverRecipientAddress(isHovered) {
      this.hover.recipientAddress = isHovered
    },
    hoverBody(isHovered) {
      this.hover.body = isHovered
    },
    hoverFooter(isHovered) {
      this.hover.footer = isHovered
    },
	}
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Freehand&family=Kristi&family=La+Belle+Aurore&family=Pinyon+Script&family=Sacramento&family=Satisfy&display=swap');
@import './letters.css';
</style>
